import LayoutA2 from "../../components/layout-a2"
import React from "react"
import EnrollInfo from '../../components/enroll-info'

export default class A2EnrollInfo extends React.Component {
  render() {
    const color=this.props.pageContext.element.color
    const src='/demos/themeA2/profile/enrollInfoDetail'
    return(
      <LayoutA2>
        <EnrollInfo src={src} color={color}/>
      </LayoutA2>
    )
  }
}